<template>
    <div class="main-wrapper" id="main-wrapper" v-if="inApp">
        <top-menu v-on:reloadCenterInfo="reloadCenterInfo" ref='topMenu'/>
        <left-menu ref="leftMenu"/>
        <div class="page-wrapper h-100">
            <breadcrumb/>
            <div class="container-fluid" id="sectionContent">
                <div class="page-content" id="final-view">
                    <router-view :key="reloadPage"/>
                    <modal-ie v-if="showError" v-on:close="closeModal" decoration="error" title="Error">
                        <div slot="content"><label>{{ error }}</label></div>
                    </modal-ie>
                </div>
            </div>
        </div>

        <!-- Global spinner (loadingPage) //-->
        <div v-show="$store.getters.isLoading" class="loading-background"></div>
        <div v-show="$store.getters.isLoading" class="spiner-ie shadow p-4 mb-4 bg-white floating always-on-top">
            <div class="text-spiner">{{ $t('loading') }}</div>
            <spinner :status="true" :color="getThemeColor()"></spinner>
        </div>
    </div>
    <div v-else id="main-wrapper-out" class="h-100 w-100 loggin-background">
        <router-view/>
      <!-- Global spinner (loadingPage) before login //-->
        <div v-show="$store.getters.isLoading" class="loading-background"></div>
        <div v-show="$store.getters.isLoading" class="spiner-ie shadow p-4 mb-4 bg-white floating">
            <div class="text-spiner">{{ $t('loading') }}</div>
            <spinner :status="true" :color="getThemeColor()"></spinner>
        </div>
    </div>
</template>

<script>
    import TopMenu from '@/components/TopMenu';
    import LeftMenu from '@/components/LeftMenu/LeftMenu';
    import Breadcrumb from '@/components/Breadcrumb';
    import Login from '@/core/Login';
    import ModalIe from "./components/ModalIe";
    import Users from "@/api/core/users";
    import menuApi from "@/api/core/menu";
    import securityHelper from "./utils/securityHelper";
    import TranslationFunctions from "./utils/TranslationFunctions";
    import $ from 'jquery';
    import { mapState } from 'vuex';
    import Spinner from "vue-spinner-component/src/Spinner";
    import ResizeSensor from 'css-element-queries/src/ResizeSensor';
    import axios from 'axios';
    import UserApi from "./api/core/users";
    import CenterApi from '@/api/core/center';
    import cssVariables from "@/assets/css/variables.js";
    import ButtonToast from '@/components/ButtonToast.vue';
    import { EventBus } from '@/eventBus';
import { currentCenter } from './store/core/currentCenter';

    export default {
        name: 'App',
        components: {
            ModalIe,
            TopMenu,
            LeftMenu,
            Breadcrumb,
            Login,
            Spinner,
            ButtonToast
        },
        data() {
            return {
                outPages: [
                    'Login',
                    'RecoverPassword',
                    'ChangePassword',
                    'twoFactor',
                    'signup',
                    'preregistrationOpen',
                    'preregistrationOpenSignUp',
                    'preregistrationSuccess'
                ],
                reloadPage:0,
                oldCenterId:undefined,
                theme: "ieduca",
                newWorker: null,
                newRegistration: null,
                needsToDeployNewVersion: false
            }
        },
        computed: {
            inApp(){
                let route = this.$route.name;
                let inApp = false;
                let trobat = false;
                let i = 0;
                while(!trobat && i < this.outPages.length ){
                    let element = this.outPages[i];
                    if (route == element) { trobat = true; }
                    i++;
                }
                if (!trobat && route!==null) {
                    inApp = true;
                }
                return inApp;
            },
            error: {
                get() {
                    return this.$store.state.error;
                }
            },
            showError() {
                if (this.$store.state.error != null) {
                    return true;
                } else {
                    return false;
                }
            },
            ...mapState({
                centerId: state => state.currentCenter.centerId,
                reloadCenter: state => state.currentCenter.reloadCenter,
                currentUser: state => state.users.currentUser
            }),
        },
        methods: {
            closeModal() {
                this.$store.commit("clearError");
            },
            async reloadCenterInfo() {
                //console.log("APP reloadCenterInfo");
                this.$store.dispatch('resetCenterInfo');
                // this.$store.dispatch('generateCancelToken');
                //Esborrar el menu quan canviem de centre
                sessionStorage.removeItem('menu');
                sessionStorage.removeItem('menuConfig');
                this.$refs.leftMenu.loadMenu();
                await this.$refs.topMenu.getCenters();
                let route = this.$route.name;
                let _self = this;
                Users.getPermissions(true).then((response) => {
                    ability.update(response.data);
                    securityHelper.hasPermission(ability, route, this.$router.options.routes);
                });
                this.$store.dispatch('currentPersonRoles', {force: true});

                // this.$store.dispatch('generateCancelToken');
            },
            async loadInfo() {
                //console.log("APP loadInfo");
                // await this.getUserLang();
                await this.$store.dispatch('generateCancelToken');
                await Users.getPermissions().then((response) => {
                    if (response && response.hasOwnProperty("data")) {
                        this.$store.commit('setPermissionsLoaded', true);
                        ability.update(response.data);
                        securityHelper.hasPermission(ability, this.$route.name, this.$router.options.routes);
                        this.$store.dispatch('currentPersonRoles', {force: false}).then(response => {
                            this.loadMenu();
                            this.$store.dispatch("getYears");
                        });
                    }
                });
            },
            async loadMenu(){
                //this.$store.dispatch("loadMenu");
            },
            async getUserLang() {
                let lang = localStorage.language;

                if (typeof lang === "undefined" || lang == "undefined" || lang == null || lang == "") {
                    await UserApi.getLang().then(response => {
                        lang = response.data.lang.toLowerCase();
                        localStorage.setItem("language", lang);
                        // console.log("setLanguage", lang);
                        this.$i18n.locale = lang;
                    });
                } else {
                    if (lang != this.$i18n.locale) {
                        this.$i18n.locale = lang;
                    }
                }
            },
            setThemeColors(){
                let colors = {
                    brand1: '#4670b5',
                    brand1_less: '#9DB7DD',
                    brand1_hsl: '217',
                    brand2: '#294c86',
                    brand2_less: '#3c63a5',
                    blackIeduca: '#152935',
                    info: '#346ecd',
                    active: '#2f5189',
                    backColor: '#f4f7fb',
                    font: 'Lato'
                };
                let transparencies = {0.05: '0d', 0.06: '0f', 0.07: '12', 0.1: '1a', 0.15: '26', 0.2: '33', 0.6: '99', 0.7: 'b3', 1: 'ff'};
                let transparenciesColors = ["brand1", "brand2", "brand1_less", "brand2_less"];

                if(cssVariables && Object.keys(cssVariables).length > 0){
                    let themes = Object.keys(cssVariables).map(n => n.split("_")[0]);
                    if(themes.findIndex(t => t == this.theme) > -1){
                        colors = cssVariables[this.theme+"_template"];
                    } else {
                        colors = cssVariables["ieduca_template"];
                    }
                }
                if(colors && !this.lodash.isNil(colors) && Object.keys(colors).length > 0){
                    document.documentElement.style.setProperty('--brand1', colors.brand1);
                    document.documentElement.style.setProperty('--brand1_less', colors.brand1_less);
                    document.documentElement.style.setProperty('--brand1_hsl', colors.brand1_hsl);
                    document.documentElement.style.setProperty('--brand2', colors.brand2);
                    document.documentElement.style.setProperty('--brand2_less', colors.brand2_less);
                    document.documentElement.style.setProperty('--blackIeduca', colors.blackIeduca);
                    document.documentElement.style.setProperty('--backColor', colors.backColor);
                    document.documentElement.style.setProperty('--info', colors.info);
                    document.documentElement.style.setProperty('--active', colors.active);
                    document.documentElement.style.setProperty('--font', colors.font);

                    transparenciesColors.forEach(trC => {
                        Object.keys(transparencies).forEach(tr => {
                            let variableName = "--" + trC + "_"+tr.replace(".","");
                            let value = colors[trC]+transparencies[tr];
                            document.documentElement.style.setProperty(variableName, value);
                        })
                    })
                }
            },
            getCenterTemplate() {
                if (this.inApp) {
                    CenterApi.getCenterTemplate().then((response) => {
                        if (response.status != 'ko') {
                            this.theme = response.data.toLowerCase();
                            localStorage.setItem("template", response.data);
                            this.setThemeColors();
                        }
                    }).catch(e => {
                        this.theme = "ieduca";
                        localStorage.setItem("template", "ieduca");
                        this.setThemeColors();
                    });
                } else {
                    this.theme = "ieduca";
                    localStorage.setItem("template", "ieduca");
                    this.setThemeColors();
                }
            },

            promptUpdate() {
                this.needsToDeployNewVersion = true;
                localStorage.setItem('needsToDeployNewVersion', '1');
                const userConsent = confirm('Nueva version detectada. ¿Actualizar a la última versión?');
                if (userConsent) {
                    this.deployNewVersion();
                }
            },
            deployNewVersion() {
                if (this.newWorker) {
                    console.log('deployNewVersion');
                    this.needsToDeployNewVersion = false;
                    this.newWorker.postMessage({ type: 'SKIP_WAITING' });
                }else {
                    console.log('No new worker to send SKIP_WAITING to.');
                }
            },
            isReloadOrNewTab(){
                if (window.performance) {
                    const entries = performance.getEntriesByType('navigation');
                    if (entries.length > 0) {
                        const navType = entries[0].type;
                        return navType === 'reload' || navType === 'navigate';
                    }
                }
                // Fallback para navegadores antiguos
                return document.referrer === '';  
            }
        },
        created(){
            if (process.env.NODE_ENV === 'production') {
                window.addEventListener('load', () => {
                    console.log("VERSIO 7 process.env.BUILD_DATE -> ", process.env.BUILD_DATE);
                    if ('serviceWorker' in navigator) {
                        try {
                            navigator.serviceWorker.register('/service-worker.js').then((registration) => {
                                console.log("service worker register -> ", registration)
                                registration.addEventListener('updatefound', () => {
                                    console.log("ON UPDATE FOUND")
                                    this.newWorker = registration.installing;
                                    this.newWorker.addEventListener('statechange', () => {
                                        if (this.newWorker.state === 'installed') {
                                            if (navigator.serviceWorker.controller) {
                                                console.log('New content is available; please refresh');
                                                this.deployNewVersion()
                                            } else {
                                                console.log('Content is cached for offline use');
                                            }
                                        }
                                        else if (this.newWorker.state === 'waiting') {
                                            console.log('New worker waiting to activate.');
                                            //window.location.reload();
                                        }
                                        else if (this.newWorker.state === 'activated') {
                                            console.log('New worker activated.');
                                            //window.location.reload();
                                        }
                                    });
                                });
                            })



                            /*
                            navigator.serviceWorker.register('/service-worker.js').then((registration) => {
                                if (registration) {
                                    registration.addEventListener("updatefound", () => {
                                        console.log('Nueva versión encontrada. Registrando...');

                                        this.newWorker = registration.waiting || registration.installing;

                                        this.newWorker.addEventListener('statechange', (e) => {
                                            console.log("STATE CHANGE -> ", this.newWorker);

                                            if (e.target.state === 'installed') {
                                                // Detectar si es refresco (F5) o una navegación normal
                                                const reloadDetected = this.isReloadOrNewTab();
                                                console.log("Reload detected: ", reloadDetected)
                                                if (reloadDetected) {
                                                    // Si es un F5, actualiza automáticamente
                                                    console.log("Es un refresco, actualizando automáticamente.");
                                                    this.deployNewVersion();
                                                } else {
                                                    // Caso normal: preguntar al usuario
                                                    const userConsent = confirm('Nueva versión disponible. ¿Actualizar ahora?');
                                                    if (userConsent) {
                                                        this.deployNewVersion();
                                                    } else {
                                                        this.needsToDeployNewVersion = true;
                                                        localStorage.setItem('needsToDeployNewVersion', '1');
                                                        console.log("Notificación cancelada, esperando actualización manual.");
                                                    }
                                                }
                                            }
                                        });
                                    });     
                                    
                                    if(registration.waiting){
                                        console.log("REGISTRATION WAITING")
                                    }
                                    if(registration.installing){
                                        console.log("REGISTRATION INSTALLING")
                                    }
                                    if(registration.active){
                                        console.log("REGISTRATION ACTIVE")
                                    }

                                    navigator.serviceWorker.addEventListener('message', (event) => {
                                        //console.log('RaddEventListener message: ', event.data);
                                        if (event.data && (event.data.type === 'ACTIVATED' || event.data.type === 'RELOAD_PAGE')) {
                                            //console.log('Received activation message from service worker.', event.data);
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            */

                            // Actualización automática al tancar la pestanya
                            /*
                            window.addEventListener('beforeunload', () => {
                                if (this.newWorker && this.needsToDeployNewVersion) {
                                    this.needsToDeployNewVersion = false;
                                    localStorage.removeItem('needsToDeployNewVersion');
                                    this.newWorker.postMessage({ type: 'SKIP_WAITING' });
                                }
                            });
                            */

                            // Errors de chunks
                            window.addEventListener('error', (e) => {
                                if (e.message.includes('Loading chunk') || e.message.includes('chunk failed')) {
                                    console.warn('⚠️ Error carregant un chunk. Actualitzant automàticament...');
                                    caches.keys().then(cacheNames => {
                                        return Promise.all(cacheNames.map(cache => caches.delete(cache)));
                                    }).then(() => {
                                        location.reload();
                                    });
                                }
                            });

                            window.addEventListener('unhandledrejection', (e) => {
                                if (e.reason?.message?.includes('Loading chunk') || e.reason?.message?.includes('chunk failed')) {
                                    console.warn('⚠️ Promesa rebutjada per error de chunk. Actualitzant automàticament...');
                                    caches.keys().then(cacheNames => {
                                        return Promise.all(cacheNames.map(cache => caches.delete(cache)));
                                    }).then(() => {
                                        location.reload();
                                    });
                                }
                            });


                        } catch (error) {
                            console.error("Registration failed with ${error}");
                        }
                    }
                });
            }
        },
        beforeMount(){
            this.getCenterTemplate();
        },
        mounted(){
            window.onbeforeunload = function (e) {
                if (performance.navigation.type == 1) {
                    if(this.$store){
                        this.$store.commit("setForceMenu", true);
                    }
                }
            }
            if(this.inApp){
                this.loadInfo();
            }
            let self = this;
            self.$store.dispatch("getWindowDeviceSize",{window: $(window)});
            $(window).resize(function() {
                self.$store.dispatch("getWindowDeviceSize",{window: $(window)});
            });
            self.$store.dispatch('getClientFromUrl');
            self.$store.dispatch('getClientImage');
            //let root = document.querySelector(':root');
            //root.style.setProperty('--brand1', '#FFA525');
            if(this.currentUser){
                this.$store.dispatch('getParameterAmbitOrAreaName');
            }
        },
        watch: {
            centerId: {
                //el olValue no té mai valor, crec que el motiu es pq bé d'un state.
                handler(newValue, oldValue){
                    if(newValue){
                        this.getCenterTemplate();
                        if(this.oldCenterId !==undefined)this.reloadPage +=1;
                        this.oldCenterId= newValue;
                    }
                }
            },
            reloadCenter(newValue){
                this.reloadCenterInfo();
            },
            inApp: {
                handler(newValue, oldValue){
                    if(newValue && !oldValue){
                        this.loadInfo();
                    }
                },
                deep: true
            },
            currentUser:{
                handler(newValue, oldValue){
                    // console.log("currentUser", this.currentUser)
                    this.$store.dispatch('getParameterAmbitOrAreaName');
                },
                deep: true
            }
        }

    }
</script>

<style lang="scss">
    #final-view {
        padding-left: 10px;
    }

    #app {
        font-family: 'Lato', 'Avenir', Helvetica, Arial, sans-serif, 'verdana';
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        margin-top: 60px;
    }

    .loggin-background {
        background: url('assets/image/core/bg@3x.png') no-repeat center center fixed;
        position: fixed;
    }

    .page-content {
        background-color: #ffffff;
        padding-left: 0px !important;
        /*padding-right: 1rem;*/
    }

    #sectionContent {
        margin-top: 2px;
        margin-left: 0rem;
        margin-right: 0rem;
        width: 100%;
    }
    #main-wrapper-out{
        overflow-y: auto;
    }

    @import "@/assets/css/global.scss";
    @import "@/assets/css/global_multidevice.scss";
    @import "../src/components/ckeditor/ckeditor_styles.css";
    @import "@/assets/css/home.scss";
</style>
