export default {
    'searchPerson': 'Nombre, Apellidos, Cédula, Usuario o Correo',
    "dni": "Cédula",
    "person.dni": "Cédula",
    "kc_invoice": {
        "cliente_cif_dni": "Cédula Cliente",
    },
    "payerNif": "RNC pagador",
    "nifRfc": "RNC Rfc",
    "nif": "RNC",
    "correctData.part1": "Confirmo que los datos ingresados",
    //criterio -> indicador
    "addCompetenceToProgram": "Asociar/Relacionar indicadores para poder evaluar",
    "addCompetence": "Añadir indicador",
    "editCompetence": "Editar indicador",
    "warning.deleteCompetence": "¿Seguro que quiere eliminar el indicador {competence}?",
    "competencesOfProgram": "Competencias y indicadores de la programación {programName}",
    "warning.deleteProgramCompetence": "¿Seguro que quiere eliminar el indicador {competence} de la programación?",
    "cantEditCompetenceWithEvaluable": "Este indicador pertenece a un evaluable. Para modificar la nota, se tiene que hacer desde la vista de evaluables.",
    "askToAddCompetences": "¿Quiere añadir indicadores a esta competencia?",
    "competencesOfDimension": "Indicadores de la competencia '{dimension}'",
    "editCompetences": "Gestionar indicadores",
    "addCompetences": "Añadir indicadores",
    "competence": "Indicador",
    "selectCompetencesToAddToEvaluable": "Selecciona indicadores para añadir al evaluable",
    "competencesOfambit": "Competencias y indicadores del ámbito '{ambit}'",
    "competencesOfarea": "Competencias y indicadores del departamento '{ambit}'",
    "selectAndAddDimensionAndCompetences": "Selecciona y añade competencias y indicadores",
    "addCompetencesToDimension": "Añadir indicadores a la competencia '{dimension}'",
    "editCompetencesOfDimension": "Indicadores de la competencia '{dimension}'",
    "selectambit": "Selecciona un ámbito del cual escoger los indicadores",
    "selectarea": "Selecciona un departamento del cual escoger los indicadores",
    "warningPercentagesNot100": "¡Atención! La suma de los porcentajes de los indicadores no llega al 100%.",
    "gradesCompetenceName": "Nombre indicador",
    "gradesCompetenceComment": "Comentario indicador",
    "noCompetenceGradesForThisPeriod": "No hay ninguna nota de indicadores puesta en este período de evaluación.",
    "saveAndAddCompetences": "Guardar y añadir indicadores",
    "criteria": "Indicador|Indicadores",
    "percentagesCompetence": "% indicadores",
    "percentagesCompetenceDescription": "Porcentaje de los indicadores en la competència",
    //fin criterio -> indicador
    //curso -> grado
    "validate.course.code.alphanumeric": "El código del grado debe ser alfanumérico (solo puede contener letras y números)",
    "course": "Grado",
    "courseStage": "Grado",
    "info.courseImpossibleRemove": "No es posible eliminar el grado.",
    "info.howToRemoveCourse": "Para eliminar el grado es necesario quitar los grupos de {group}.",
    "ufView": "Vista Grados | Vista Uf",
    "elementUnsetConvoCourseStage": "Se ha desasignado la convocatoria del grado correctamente",
    "noConvocatoryCourseStage": "No hay convocatorias asignadas a este grado",
    "groupType.COURSESTAGE": "Grado oficial",
    "coursestages": "Grado | Grados",
    'selectedCourseDoesNotMatchSelectedGroup': 'El grado seleccionado no concuerda con el grupo indicado. Por favor, selecciona otro grupo.',
    "stageCourseStage": "Nivel y grado",
    'gradesCourseName': 'Grado',
    "noCourses": "No hay grados disponibles",
    "deleteStageWarning": "¿Está seguro de querer eliminar el nivel, los grados y los grupos de {stage}?",
    "cantDeleteCourse": "No es posible eliminar el grado {course} porque tiene grupos con alumnos matriculados.",
    "deleteStageCourse": "¿Está seguro que desea eliminar el grado {course}?",
    "deleteConvocatoryCSConfirmation": "¿Seguro que quiere eliminar esta convocatoria de este grado?",
    "nCourseStagesSelected": "{n} grados seleccionados",
    "noStagePrograms": "No hay programaciones en este grado.",
    "subject.ProgramsTitle": " se imparte en los niveles y grados siguientes: ",
    "noProgramSubject": "No está definido a qué niveles y grados se imparte la materia.",
    "courses": "Grado | Grados",
    "noCoursesForStage": "El nivel no tiene ningún grado",
    "deleteStageCourseAndTimetableEvents": "¿Está seguro que desea eliminar el grado {course}? También se van a borrar todos los eventos del horario asociados al grado.",
    "selectCourseStagePrintGrades": "Selecciona un grado para descargar las notas",
    "cantPrintGradesWithoutCourse": "No se pueden descargar las notas porque esta persona no está en ningún grado.",
    "selectCourseStageForTemplate": "Selecciona para qué grados quieres utilizar la plantilla '{template}'",
    "clickToSpecifyCourseStageForTemplate": "Haz click aquí para definir a qué grados aplica esta plantilla",
    "defineCourses": "Definir grados",
    "changeCourseStage": "Cambiar de grado",
    "groupHasNoPrograms": "No se han definido las materias que se imparten en este nivel y grado. Haz click en el botón para añadir.",
    "publicForNCourseStages": "{n} grados visibles para familias",
    "courseStageHasNoPrograms1": "Este nivel-grado no tiene materias configuradas. Las puedes configurar en ",
    "selectCourseStageToCreateGroupInAS": "Por favor, selecciona un nivel y grado si quieres que este grupo se cree en la Estructura Académica.",
    "courseStageHasNoStudents": "Este grado no tiene ningún alumno.",
    "emptyCourseStage": "El grado està vacío.",
    "templateAlreadyDefinedForCSAndModule": "Este grado ya tiene una plantilla definida, si selecciona otra, se borrará la actual.",
    "deleteSubjectFromCourseStage": "¿Seguro que quiere borrar la materia {subject} del grado {courseStage}?",
    "coursePredefinedDontMatch": "El grado no coincide con los datos predefinidos, tendría que ser: {group}",
    "noCoursesCreated": "No hay ningún grado creado.",
    "allCourseStages": "<span class='badge badge-info badge-pill'>Todos</span> los grados|Todos los grados",
    "appliesToCourseStages": "Grados a los que aplica",
    "printByLevelCourse": "Imprimir por nivel y grado",
    "subjectsOfCourse": "Materias del grado '{course}'",
    "noSubjectsOfSelectedCourse": "No hay materias en el grado seleccionado.",
    "noSubjectsInCourseForThisarea": "No hay materias en este grado para este departamento.",
    "noSubjectsInCourseForThisambit": "No hay materias en este grado para este ámbito.",
    "isManualTimetableClasse": "Información de la clase con id {classeId}: <ul class='default-list'><li>Creador: <strong>{createdBy}</strong></li><li>Fecha: <strong>{date}</strong></li><li>Grupo: <strong>{groupName}</strong> ({groupId})</li><ul class='default-list'><li>Tipo: {type}</li></ul><li>Nivel: <strong>{stageName}</strong> ({stageId})</li><li>Grado: <strong>{courseStageName}</strong> ({courseStageId})</li></ul>",
    "noCompetencesAssigned": "No hay competencias asignadas a este grado.",
    "addCompetencesToCourseStage": "Añadir competencias al grado '{courseStage}'",
    "emptyCourseFieldImportCompetencesMeansDeleteAll": "Si el campo 'Códigos grados' está vacío, se eliminará la competencia de todos los grados.",
    "emptyCourseFieldImportCompetencesMeansAddAll": "Si el campo 'Códigos grados' está vacio, se añadirá la competencia a todos los grados.",
    "error.CourseStageNotFound": "No se ha encontrado el grado '{courseStage}'",
    "noReportDefinedForThisCourse": "No hay ningún boletín definido para este grado.",
    "errorReportDefinedForThisCourse": "No esta bien configurado el boletín para este grado.",
    "noCompetencesAssignedToCourseAndAmbit": "No hay competencias asignadas al grado y ámbito seleccionados.",
    "appliesToCurrentCourseStage": "Aplica al grado actual",
    "deleteCompetencesFromCourseStageTitle": "Eliminar competencias del grado {courseStage}",
    "applyCourseAndIsDefault": "Hay {count} que aplica al grado y está por defecto.|Hay {count} que aplican al grado y están por defecto.",
    "applyCourseAndNotDefault": "Hay {count} que aplica al grado y no está por defecto.|Hay {count} que aplican al grado y no están por defecto.",
    //fin curso->grado
}