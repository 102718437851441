import axios from 'axios';

export default {
    getActiveTimeTable(dateIni, dateEnd, year = null) {
        let params = {'dateIni': dateIni, 'dateEnd': dateEnd};
        if(year != null){
            params["year"] = year;
        }
        return axios.get('/timetabledef', {params});
    },
    getTimetableActives() {
        return axios.get('/timetableactives');
    },
    saveTimetableCategory(category) {
        let params = {'category': category};
        return axios.post('/timetable/category', {params});
    },
    saveTimetableCategories(categories) {
        let params = {'categories': categories};
        return axios.post('/timetable/categories', {params});
    },
    duplicateEvent(params) {
        return axios.post('/timetabledraft/eventrepetition/duplicate', {params});
    },
    saveTimetableSlot(timetableSlot, yearCode = null) {
        let params = {'timetableSlot': timetableSlot};
        if(yearCode){
            params['yearCode'] = yearCode;
        }
        return axios.post('/timetableSlot', {params});
    },
    saveTimetableSlots(timetableSlots, yearCode = null) {
        let params = {'timetableSlots': timetableSlots};
        if(yearCode){
            params['yearCode'] = yearCode;
        }
        return axios.post('/timetableSlots', {params});
    },
    getTimetableSlots(schemaName) {
        let params = {'schemaName': schemaName};
        return axios.get('/timetableSlots', {params});
    },
    saveTimetableDraftEventRepetition(oldEventId, event, draft) {
        let params = {'oldEventId': oldEventId, 'event': event, 'draft': draft};
        return axios.post('/timetabledraft/eventrepetition/', {params});
    },
    getDraftOfTimetableActive(id) {
        return axios.get('/timetableactive/' + id + '/draft');
    },
    getActiveEventsBetweenDates(cancelToken, fromDate, toDate, filters) {
        let params = {'fromDate': fromDate, 'toDate': toDate, 'filters': filters};
        return axios.get('/timetableactive/events', {params: params,
            cancelToken: cancelToken.token });
    },
    getRepetitionEventsBetweenDates(timetableDraftId, fromDate, toDate, beforeDate, filters, schemaName) {
        let params = {
            'fromDate': fromDate,
            'toDate': toDate,
            'beforeDate': beforeDate,
            'filters': filters,
            'schemaName': schemaName
        };
        return axios.get('/timetabledraft/' + timetableDraftId + '/repetitionevents', {params});
    },
    getRepetitionEvent(eventId) {
        return axios.get('timetabledrafteventrepetition/' + eventId);
    },
    getActiveEvent(eventId) {
        return axios.get('timetableactiveevent/' + eventId);
    },
    getTimetableDrafts(schemaName) {
        let params = {'schemaName': schemaName};
        return axios.get('/timetabledrafts', {params});
    },
    getTimetableDraft(draftId, schemaName) {
        let params = {"schemaName": schemaName};
        return axios.get('/timetabledraft/' + draftId, {params});
    },
    getTimetableDraftRepetitionEvents(draftId) {
        return axios.get('/timetabledraft/' + draftId + '/repetitionevents')
    },
    getCategories(onlyActives, schemaName) {
        let params = {'onlyActives': onlyActives, 'schemaName': schemaName};
        return axios.get('/timetable/categories', {params});
    },
    activateTimetableDraft(timetableDraftId, activateTimetableDraftInfo) {
        let params = {'activateTimetableDraftInfo': activateTimetableDraftInfo};
        return axios.post('timetabledraft/' + timetableDraftId + '/activate', {params});
    },
    removeRepetitionEvent(eventId) {
        return axios.delete('/timetablerepetitionevent/' + eventId);
    },
    saveTimetableDraft(timetableDraft, year) {
        let params = {'timetableDraft': timetableDraft};
        if(year != undefined){
            params.year = year;
        }
        return axios.post('/timetabledraft', {params});
    },
    getWorkingDays() {
        return axios.get('/timetable/workingdays');
    },
    removeTimetableSlot(slotId, yearCode = null) {
        let params = {};
        if(yearCode){
            params['yearCode'] = yearCode;
        }
        return axios.delete('/timetable/slot/' + slotId, {params});
    },
    removeTimetableCategory(categoryId) {
        return axios.delete('timetable/category/' + categoryId);
    },
    changeRepetitionEventDateTime(eventId, dayNum, hourIni, hourEnd, fromDate) {
        let params = {'dayNum': dayNum, 'hourIni': hourIni, 'hourEnd': hourEnd, 'fromDate': fromDate};
        return axios.post('/timetable/repetitionevent/' + eventId + '/datetime', {params});
    },
    getRepetitionEventsOfTimetableSlot(slotId, yearCode = null) {
        let params = {};
        if(yearCode){
            params['yearCode'] = yearCode;
        }
        return axios.get('/timetable/slot/' + slotId + '/repetitionEvents', {params});
    },
    exportActiveEventsBetweenDates(timetableActiveId, fromDate, toDate, filters, format) {
        let params = {'fromDate': fromDate, 'toDate': toDate, 'filters': filters};

        return axios.get('/timetableactive/' + timetableActiveId + '/export/' + format, {params});
    },
    exportDraftEventsBetweenDates(timetableDraftId, fromDate, toDate, filters) {
        let params = {'fromDate': fromDate, 'toDate': toDate, 'filters': filters};

        return axios.get('/timetabledraft/' + timetableDraftId + '/export/', {params});
    },
    printPDF(timetableActiveId, fromDate, toDate, filters, templateId, printType) {
        let parameters = new URLSearchParams();
        parameters.set('fromDate', fromDate);
        parameters.set('toDate', toDate);
        parameters.set('filters', JSON.stringify(filters));
        parameters.set('templateId', templateId);
        parameters.set('printType', printType);
        return axios.get('/timetableactive/' + timetableActiveId + '/export/pdf?' + parameters,
            {responseType: 'arraybuffer'});
    },
    removeTimetableDraft(timetableDraftId){
        return axios.delete('/timetabledraft/'+timetableDraftId);
    },
    findRepetitionEvents(timetableDraftId, schema, beforeDate, filters, sort, pag){
        let params = {schemaName: schema, beforeDate: beforeDate, filters: filters, sort: sort, pagination: pag};
        return axios.get('/timetabledraft/'+timetableDraftId+'/repetitionevents/find', {params});
    },
    getStageCourseStageAndSubjectByGroup(groupId, schemaName){
        let params = {groupId: groupId, schemaName: schemaName};
        return axios.get('/stageCourseStageAndSubjectByGroup', {params});
    },
    getStageCourseStageAndSubjectByStudent(studentId){
        let params = {studentId: studentId};
        return axios.get('/stageCourseStageAndSubjectByStudent', {params});
    },
    getYearDates(yearCode){
        let params = {yearCode: yearCode};
        return axios.get('/timetable/yearDates',{params});
    },
    saveTimetableDraftName(timetableDraftId, name){
        let params = {'name': name};
        return axios.post('/timetabledraft/'+timetableDraftId+'/saveName', {params});
    },
    getPendingIndividualClassesToCreate(timetableDraftId, schemaName){
        return axios.get('/timetabledraft/'+timetableDraftId+'/pendingIndividualClasses/'+schemaName);
    },
    addPendingEventsToTimetable(timetableDraftId, pendingEvents, schemaName){
        return axios.post('/timetabledraft/'+timetableDraftId+'/addPendingEvents', {'events': pendingEvents, 'schemaName': schemaName});
    },
    cancelActivation(activationId) {
        return axios.delete('/timetabledraft/cancel-activation/' + activationId);
    }
}
