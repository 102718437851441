/**
 * FileManager module.
 */
import UserApi from "@/api/core/users"
import lodash from 'lodash'

function searchFolder(folders, searchId) {
    let foundFolder = null;

    for (let folder of folders) {
        if (folder.id == searchId) {
            foundFolder = folder;
        } else if (Array.isArray(folder?.children) && folder.children.length > 0) {
            foundFolder = searchFolder(folder.children, searchId);
        }
        if (foundFolder != null) {
            return foundFolder;
        }
    }

    return null;
}

export const fileManager = {
    state: {
        folders: [],
        currentFolder: null,
        currentFile: null,
        fileManagerError: null
    },
    mutations: {
        setCurrentFolder(state, folder){
            state.currentFolder = lodash.cloneDeep(folder)
        },
        setCurrentFile(state, file){
            state.currentFile = lodash.cloneDeep(file)
        },
        setFolders(state, folders) {
            state.folders = lodash.cloneDeep(folders)
        },
        setFileManagerError(state, error) {
            state.fileManagerError = error
        }
    },
    actions: {
        async loadUserHome({ dispatch, commit }, payload) {
            let showSharedFolders = false;
            let currentFolderId = null;

            if (payload && payload.showSharedFolders) {
                showSharedFolders = true;
            }

            if (payload && payload.currentFolderId) {
                currentFolderId = payload.currentFolderId;
            }

            commit("setFileManagerError", "")
            dispatch("startLoading", null, { root: true });
            await UserApi.getUserHome(showSharedFolders).then(response => {
                let userFolder = []

                if (response && response.data.status == "ok" && Array.isArray(response.data.userFolder) && response.data.userFolder.length > 0) {
                    userFolder = response.data.userFolder
                    commit("setFolders", userFolder)
                    if (currentFolderId != null) {
                        let folderToLoad = searchFolder(userFolder, currentFolderId);
                        if (folderToLoad != null) {
                            commit("setCurrentFolder", folderToLoad);
                        }
                    } else if (userFolder.length > 0) {
                        commit("setCurrentFolder", userFolder[0])
                    }
                } else {
                    commit("setFileManagerError", "error.loadUserFolder")
                }
                dispatch("stopLoading", null, { root: true });
            }).catch(e => {
                commit("setFileManagerError", "error.loadUserFolder")
                dispatch("stopLoading", null, { root: true });
            })
        },
        selectFolder({ commit }, folder) {
            commit("setFileManagerError", "")
            commit("setCurrentFolder", folder)
        },
        addFileToFolder({ dispatch, commit, state }, payload) {
            let queue = [state.folders[0]]
            let folder = null
            let modified = false

            commit("setFileManagerError", "")
            while (queue.length > 0) {
                folder = queue.shift()
                if (folder.id == payload.folder.id) {
                    folder.files.push(payload.file)
                    modified = true
                    break
                }

                for (let i = 0, len = folder.children.length; i < len; i++) {
                    queue.push(folder.children[i])
                }
            }

            if (modified) {
                commit("setCurrentFolder", folder)
            } else {
                commit("setFileManagerError", "error.canNotModifyFolder")
            }
        },
    },
    getters: {
        getCurrentFolder: state => state.currentFolder,
        getCurrentFile: state => state.currentFile,
        getFolders: state => state.folders,
        getFileManagerError: state => state.fileManagerError
    }
}
